<template>
    <login-and-regist-layout
        :title="$t('forgotPassword.resetPassword')"
        :loading="isLoading"
    >
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
                <v-row>
                    <v-col md="10" class="mx-auto">
                        <v-form
                            ref="numberForm"
                            v-model="phoneValid"
                            lazy-validation
                        >
                            <v-text-field
                                autofocus
                                v-model="phoneNumber"
                                type="number"
                                :label="$t('login.phoneNumber')"
                                :placeholder="
                                    $t('signUp.phoneNumberPlaceholder')
                                "
                                prepend-inner-icon="mdi-phone"
                                :rules="fieldRule(phoneNumberRule)"
                                persistent-placeholder
                                validate-on-blur
                                :disabled="!isCanChangeNumber"
                                append-outer-icon="mdi-send-check"
                                @click:append-outer="validateNumber"
                            ></v-text-field>
                        </v-form>
                        <template v-if="!isCanChangeNumber">
                            <v-text-field
                                v-model="newPassword"
                                :label="$t('forgotPassword.newPassword')"
                                :placeholder="
                                    $t('formField.formFieldPlaceholder', {
                                        field: $t('forgotPassword.newPassword'),
                                    })
                                "
                                type="password"
                                prepend-inner-icon="mdi-lock"
                                :rules="fieldRule(passwordRule)"
                                persistent-placeholder
                                autocomplete="new-password"
                                validate-on-blur
                            ></v-text-field>
                            <v-text-field
                                v-model="newPasswordConfirm"
                                :label="$t('forgotPassword.newPasswordConfirm')"
                                :placeholder="
                                    $t('formField.formFieldPlaceholder', {
                                        field: $t(
                                            'forgotPassword.newPasswordConfirm'
                                        ),
                                    })
                                "
                                type="password"
                                prepend-inner-icon="mdi-lock"
                                :rules="fieldRule(passwordConfirmRule)"
                                persistent-placeholder
                                autocomplete="new-password"
                                validate-on-blur
                            ></v-text-field>
                            <v-btn
                                block
                                rounded
                                :loading="isLoading"
                                class="primary mt-5"
                                @click="onResetPassword"
                            >
                                {{ $t("forgotPassword.resetPassword") }}
                            </v-btn>
                        </template>
                        <p class="text-center mt-10 mb-3">
                            {{ $t("signUp.loginSuggest") }}
                        </p>
                        <router-link to="/login" class="text-decoration-none">
                            <p
                                class="text-center text-subtitle-1 text-uppercase"
                            >
                                {{ $t("login.title") }}
                            </p>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <otp-dialog
            :isShowDialog.sync="otpDialog"
            @onDialogConfirm="onDialogConfirm"
        ></otp-dialog>
        <div :id="recaptchaContainer" />
    </login-and-regist-layout>
</template>

<script>
import LoginAndRegistLayout from "@/layout/LoginAndRegistLayout.vue";
import commonFunc from "@/common/commonFunc.js";
import useVerifyOTP, { RECAPTCHA_CONTAINER } from "@/mixins/useVerifyOTP.js";
import screen from "@/mixins/screen.js";
import { mapActions, mapGetters } from "vuex";
import OTPDialog from "@/components/OTPDialog.vue";
import store from "@/store";

export default {
    components: {
        LoginAndRegistLayout,
        "otp-dialog": OTPDialog,
    },
    mixins: [useVerifyOTP, screen],
    data() {
        return {
            valid: true,
            phoneValid: true,
            phoneNumber: "",
            newPassword: "",
            newPasswordConfirm: "",
            otpDialog: false,
            isCanChangeNumber: true,
            recaptchaContainer: RECAPTCHA_CONTAINER,
            token: null,
            phoneNumberRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("login.phoneNumber"),
                    }),
                (v) =>
                    /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/.test(v) ||
                    this.$t("formField.formFieldInvalid", {
                        field: this.$t("login.phoneNumber"),
                    }),
            ],
            passwordRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("signUp.password"),
                    }),
            ],
            passwordConfirmRule: [
                (v) =>
                    !!v ||
                    this.$t("formField.formFieldRequired", {
                        field: this.$t("signUp.confirmPassword"),
                    }),
                () =>
                    this.newPassword === this.newPasswordConfirm ||
                    this.$t("signUp.passwordConfirmError"),
            ],
        };
    },
    beforeRouteEnter(from, to, next) {
        store.dispatch("auth/resetPhoneStatus");
        next();
    },
    computed: {
        ...mapGetters({
            isPhoneAvailable: "auth/isPhoneAvailable",
            isAuthenticated: "auth/isAuthenticated",
        }),
    },
    watch: {
        isAuthenticated(newVal) {
            if (newVal) {
                this.informationMsg = this.$t(
                    "forgotPassword.successResetPassword"
                );
                this.$router.push("/marketing"); 
            }
        },
        async isPhoneAvailable(newVal) {
            if (newVal === false) {
                await this.sendOTP(commonFunc.formatPhone(this.phoneNumber))
                    .then(() => {
                        this.otpDialog = true;
                    })
                    .catch((error) => {
                        this.setError(error);
                    });
            } else if (newVal === true) {
                this.setError(this.$t("forgotPassword.accountNotExist"));
            }
        },
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        validateNumberForm() {
            this.$refs.numberForm.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        async validateNumber() {
            if (this.phoneValid) {
                this.checkPhoneNumber(
                    commonFunc.toPhoneNumber(this.phoneNumber)
                );
            }
        },
        async onResetPassword() {
            this.validate();
            if (this.valid) {
                this.customerResetPassword({
                    username: commonFunc.formatPhone(this.phoneNumber),
                    token: this.token,
                    password: this.newPassword,
                });
            }
        },
        async onDialogConfirm(otpCode) {
            try {
                const token = await this.confirmOTP(otpCode);
                this.otpDialog = false;
                this.isCanChangeNumber = false;
                this.token = token;
            } catch (error) {
                this.setError(error);
            }
        },
        ...mapActions({
            checkPhoneNumber: "auth/checkPhoneNumber",
            customerResetPassword: "auth/customerResetPassword",
        }),
    },
};
</script>
